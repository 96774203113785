import React from "react";
import styled from "@emotion/styled";
import Slider from "./slider";
import mainHeroImg from "../assets/images/dp-home-header.jpg";

const ImageBackground = styled("div")`
  background: #222 url(${mainHeroImg});
  // background-color: #111;
  color: white;
  background-position: top 0% center;
  background-size: cover;
  min-height: 400px;
  padding-top: 10px;
  padding-bottom: 35px;
  // height: 60vh;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // text-align: left;
  @media (max-width: 680px) {
    background-position: top 100% center !important;
    min-height: 200px;
    padding-left: 10px;
  }
`;

const FeaturePanel = styled("div")`
  color: white;
  font-size: 1rem;
  max-width: 980px;
  margin: 0 auto;
  margin-top: 20px;
  padding-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;

  > div {
    background-color: #d9e8ef;
  }

  p {
    /* background-color: hotpink; */
    color: #000;
    margin-top: 0;
    padding: 15px;
    text-transform: none;
    font-size: 0.85rem;
  }

  img {
    margin-bottom: 0;
  }

  h3 {
    /* text-align: center; */
    color: #000 !important;
    text-transform: uppercase;
    padding: 10px 0px 6px 0px;
    margin-left: 15px;
    margin-right: 15px;
    font-weight: 600;
    border-bottom: solid 1px #666;
  }

  @media (max-width: 680px) {
    grid-template-columns: 1fr;
  }
`;

const Hero = () => {
  return (
    <>
      <ImageBackground>
        <Slider />
      </ImageBackground>
      <div
        style={{
          padding: "22px",
          backgroundColor: "#33afc0",
          fontSize: "1.5rem",
          color: "white",
          textAlign: "center"
        }}
      >
        Phone: (062) 55121
        <a href="/Deerpark Brochure.pdf" target="_blank" style={{
        display: "inline-block",
        padding: "10px",
        backgroundColor: "#c1170f",
        textDecoration: "none",
        color: "white",
        borderRadius: "4px",
        fontSize: "18px",
        marginLeft: "40px"
      }}>Download Our Brochure</a>


      </div>
    </>
  );
};

export default Hero;
