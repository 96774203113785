import React from "react";
import Layout from "../components/layout";
import Hero from "../components/hero";
import deerparkCare from "../assets/images/deerpark-care.jpg";

export default () => {
  return (
    <Layout>
      <Hero />
      <div
        style={{
          paddingTop: "5px",
          paddingBottom: "20px",
          marginTop: "30px",
        }}
      >
        <div className="main-content">
          <section>
            <div className="home-about" style={{ display: "flex" }}>
              <div style={{ flex: "2" }}>
                <h3
                  style={{
                    marginTop: "0",
                    paddingTop: "0",
                    marginRight: "20px",
                  }}
                >
                  Welcome to Deerpark Nursing Home
                </h3>

                <p>
                  Situated just Skm outside Tipperary Town, and only half an
                  hour from Limerick city, Deerpark has been a family run
                  nursing for over 20 years.
                </p>
                <p>
                  With landscaped gardens overlooking the Galtee mountains,
                  residents and their families are immediately put at ease both
                  by the peaceful location and the homely nature of the nursing
                  home and its excellent staff.
                </p>
                <p>
                  This purpose built nursing home was designed to accommodate
                  interconnecting corridors creating a safe and peaceful
                  courtyard for residents to enjoy. The courtyard includes a
                  patio area which is accessible to all residents at any time
                  and enables the residents to grow herbs and vegetables and
                  take part in gardening activities as part of their daily
                  living. Alternatively they can just relax in one of the many
                  seating areas and enjoy the fresh air and peaceful location.
                </p>
                <p>
                  We would very much like you to visit Deerpark to see for
                  yourselves the level of service we offer. Mairead, Rhea or a
                  member of staff will be happy to show you around. Visiting
                  times are open, within reason of course, we love to see
                  families popping in, like you would in your own home!
                </p>
                <p>
                  We place utmost emphasis on patient happiness and well-being.
                  Our activities programme ensures a wide breadth of interests
                  are catered for.
                </p>
              </div>
              <div className="home-service-list" style={{ flex: "1" }}>
                <div
                  style={{
                    maxWidth: "350px",
                    paddingLeft: "40px"
                  }}
                >
                  <img src={deerparkCare} alt="" style={{ maxWidth: "100%" }} />
                  <span style={{
                    backgroundColor: "#dfdfdf",
                    fontWeight: "bold",
                    fontSize: "1rem",
                    fontStyle: "italic",
                    display: "block",
                    padding: "8px"
                  }}>
                    Dedicated to providing the highest standards of care and
                    quality of life for all our residents.
                  </span>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
};
