 import React, { Component } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
    };
    return (
      <div style={{ maxWidth: "100%" }} className="slide-container fade-in">
        <div style={{ maxWidth: "980px", margin: "0 auto" }}>
          <Slider {...settings}>
            <div>
              <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr'}}>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div style={{maxWidth: '300px'}} className="slider-layered">
                  {/* slide content here */}
                  
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    );
  }
}
